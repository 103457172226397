import axios, { AxiosError, AxiosResponse } from 'axios'
import { ObjectId, WithId } from 'mongodb'

const uri = process.env.NODE_ENV !== 'production' ? 'http://localhost:7071' : 'https://ephoria-functions.azurewebsites.net'

axios.defaults.baseURL = uri
axios.defaults.headers.common['Content-Type'] = 'application/json'

enum Method {
    get,
    post,
    patch,
    delete
}

export interface IClientSession {
	id: string
}


export interface IUser {
    usage: number
    name?: string
    email?: string
}

export interface IAccount {
    name: string
}

export interface IQRCode {
    account: ObjectId
    name: string,
    createdBy: ObjectId
    createdDate: Date
    code: string
    redeemCounter: number
    maxUsers: number
    licenseDurationDays: number
    expirationDate?: Date
}

export interface IStat {
    date: Date

    requests: number
    promptTokens: number
    completionTokens: number
    totalTokens: number
    maxTpm?: number
    maxRpm?: number
}

export interface IAnswer {
    text: string
    hasError: boolean
    options?: string[]
    hasFollowUp?: boolean
    feature?: boolean
    rating?: number
    intervene?: boolean
    role: "system" | "assistant" | "user"
}


export interface IDashboardData {
    totalUsers: number
    usage: {date: Date, interactions: number}[]
}


export default class BusinessClient {

    session: IClientSession|undefined

    private static _instance: BusinessClient

    // use this to access datamanager from anywhere
    // e.g. DataManager.instance.client
    static get instance(): BusinessClient {
        if (BusinessClient._instance == null)
            BusinessClient._instance = new BusinessClient()

        return this._instance
    }

    async users(): Promise<WithId<IUser>[]> {
        const response = await this.request(Method.get, '/api/business/users')
        return response
    }

    async account(): Promise<WithId<IAccount>> {
        const response = await this.request(Method.get, `/api/business/account`)
        return response
    }

    async dashboard(): Promise<IDashboardData> {
        // sleep 5s
        // await new Promise(resolve => setTimeout(resolve, 5000))
        const response = await this.request(Method.get, '/api/business/dashboard')
        return response
    }

    async createQRCode(name: string, validDays: number, maxUsers: number, licenseLimit: number): Promise<IAnswer> {
        const response = await this.request(Method.post, '/api/business/qrcodes', { name, validDays, maxUsers, licenseLimit })
        return response
    }

    async deactivateQRCode(code: string): Promise<IAnswer> {
        const response = await this.request(Method.post, `/api/business/qrcodes/${code}/deactivate`)
        return response
    }

    async listQRCodes(): Promise<WithId<IQRCode>[]> {
        const response = await this.request(Method.get, `/api/business/qrcodes`)
        return response
    }

    async config(): Promise<WithId<IStat>[]> {
        const response = await this.request(Method.get, '/api/business/config')
        console.log('response:', response)
        return response
    }


    async authenticate(email: string, password: string, tan: string): Promise<boolean> {
        this.session = await this.request(Method.post, '/api/auth', { email, password, tan, backend: true})
            .catch((reason) => {
                console.log('authentication error:', reason)
                this.session = undefined
            })

        if(this.session) {
            axios.defaults.headers.common['x-session'] = this.session.id
            return true
        }

        return false
    }





    private request = async (method: Method, path: string, data: object = {} ): Promise<any> => {

        const handleErrorOnRequest = async (error: AxiosError) => {
            // check if could connect to server
            if(!error.response) {
                console.log("failed to connect to server")
                return // do not process next lines, because there's no error code to read
            }

            // connection was made, check http error code
            const code = error.response.status
            console.log('error code', code)

            if(code === 401) {
                // session not found
                console.log('401: invalid session')
                this.session = undefined
                return
            }

            console.error('unhandled axios error:', error)
        }

        // setup request
        console.log('req', path, method);

        // define the web request
        let request
        if     (method === Method.get)    request = axios.get(path)
        else if(method === Method.post)   request = axios.post(path, data)
        else if(method === Method.patch)  request = axios.patch(path, data)
        else if(method === Method.delete) request = axios.delete(path)

        if(!request) throw new Error('invalid http method')
        // perform the request and pass errors to our handler
        //request.catch(async (error: AxiosError) => {
        const response = await request.catch(async (error: AxiosError) => {
            handleErrorOnRequest(error)
            // throw(error)

            // increasing wait/retry
            //await this.increasingWait()

            return
            //return await this.request(method, path, data)
        }) as AxiosResponse<any>

        //const responseData = (await request).data

        // the following won't be executed on error:
        //this.waitForRetry = waitForRetryDefault
        //this.serverIsReachable = true
        return response.data
    }
}