
import Dashboard from "../pages/Dashboard";
import Codes from "../pages/Codes";
import Settings from "../pages/Settings";

// https://mui.com/material-ui/material-icons/
import SettingsIcon from '@mui/icons-material/Settings';
import QrCodeIcon from '@mui/icons-material/QrCode';
import MultilineChartIcon from '@mui/icons-material/MultilineChart';

import { RouteType } from "./config";

const appRoutes: RouteType[] = [
  {
    index: true,
    element: <Dashboard />,
    state: "home"
  },
  {
    path: "/",
    element: <Dashboard />,
    state: "home",
    sidebarProps: {
      displayText: "Dashboard",
      icon: <MultilineChartIcon />
    }
  },
  {
    path: "/codes",
    element: <Codes />,
    state: "accounts",
    sidebarProps: {
      displayText: "Zugangscodes",
      icon: <QrCodeIcon />
    }
  },
  {
    path: "/settings",
    element: <Settings />,
    state: "settings",
    sidebarProps: {
      displayText: "Einstellungen",
      icon: <SettingsIcon />
    }
  },

]

export default appRoutes