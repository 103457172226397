import React, { useState } from "react"

import BusinessClient, { IDashboardData } from "../lib/BusinessClient"


import { XAxis, YAxis,ResponsiveContainer, Bar, BarChart, Tooltip, CartesianGrid } from 'recharts';
import { Card, LinearProgress, Paper, Typography } from "@mui/material";


const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Monate sind 0-basiert
    return `${day}.${month}.`;
};

const Dashboard = () => {
    const [initialized, setInitialized] = useState<boolean>(false)
    const [data, setData] = useState<IDashboardData>()

    React.useEffect(() => {
        const fetch = async () => {
            const result = await BusinessClient.instance.dashboard()
            setData(result)
            setInitialized(true)

        }

        if(!initialized) fetch()
    })

    return (
        <Paper sx={{padding: 3}}>

            <Typography component="h5" variant="h5" gutterBottom>
                Dashboard
            </Typography>

            <Card sx={{padding: 3, marginBottom: 3}}>
                <Typography variant="h6" gutterBottom>
                    Benutzer
                </Typography>
                <Typography variant="h2" gutterBottom>
                    {data?.totalUsers ?? '-'}
                </Typography>
            </Card>

            <Card sx={{padding: 3, marginBottom: 3}}>
                <Typography variant="h6" gutterBottom>
                    Interaktionen in den letzten 30 Tagen
                </Typography>
                <Typography variant="body1" gutterBottom>
                Beispiele für qualifizierte Interaktionen: Tagebucheintrag, Gespräch geführt, Fragebogen ausgefüllt etc.
                </Typography>
                {data &&
                <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={data?.usage}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" tickFormatter={formatDate} />
                        <YAxis />
                        <YAxis />
                        <Tooltip />
                        <Bar dataKey="interactions" fill="#8884d8" />
                    </BarChart>
                </ResponsiveContainer>
                }
                {!data && <LinearProgress style={{marginTop: 50, alignSelf: 'center'}} />}
            </Card>

        </Paper>
    )
}

export default Dashboard
